import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  TableContainer,
  TableBody,
  TableHead,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  Paper,
  TableCell,
  TableRow,
  useMediaQuery,
  Chip,
} from "@mui/material";

import LoginIcon from "@mui/icons-material/Login";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));

// ********************* COMPONENT START ***********************

function AdminUsersRow({
  userItem,
  colWidths,
  editUser,
  deleteUser,
  loginAs,
  addChannels,
  deleteChannelAssociation,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [users, setUsers] = useState([]);
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <TableRow style={{ backgroundColor: userItem.active ? "" : "#ff7e82" }}>
      <TableCell style={{ width: colWidths.col1 }}>{userItem.name}</TableCell>
      <TableCell style={{ width: colWidths.col2 }} align="center">
        {userItem.email}
      </TableCell>
      <TableCell style={{ width: colWidths.col3 }} align="center">
        {userItem.status}
      </TableCell>
      <TableCell style={{ width: colWidths.col4 }} align="center">
        {userItem.channelsAssociations.map((association) => {
          return (
            <Chip
              key={association.id}
              size="small"
              sx={{ margin: "2px" }}
              label={association.Channel.name}
              onDelete={() => {
                deleteChannelAssociation(association);
              }}
            />
          );
        })}
        <Chip
          label={"Ajouter"}
          size="small"
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: "white",
          }}
          onClick={(e) => {
            addChannels(userItem);
          }}
        />
      </TableCell>
      <TableCell style={{ width: colWidths.col5 }} align="center">
        {user.status == "admin" ? (
          <IconButton
            aria-label="Login as"
            onClick={() => {
              loginAs(userItem);
            }}
          >
            <LoginIcon />
          </IconButton>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <IconButton
          aria-label="settings"
          onClick={() => {
            editUser(userItem);
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="settings"
          onClick={() => {
            deleteUser(userItem);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

AdminUsersRow.propTypes = {};

// ********************* COMPONENT END ***********************

export default AdminUsersRow;
